@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply text-4xl font-extrabold
    }

    h2 {
        @apply text-2xl font-semibold
    }

    h3,
    h4,
    h5,
    h6 {
        @apply text-xl font-semibold
    }
}

@layer utilities {
    @variants responsive {

        /* Hide scrollbar for Chrome, Safari and Opera */
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        .no-scrollbar {
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */
        }
    }

    html {
        letter-spacing: 1px;
        font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", メイリオ, Meiryo, sans-serif;
        color: #333333;
    }

    .tc-table__wrap {
        border: 1px solid #dbdbe2;
        border-radius: 3px;
        position: relative;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
    }

    .tc-table {
        width: 100%;
        height: 100%;
        border-collapse: collapse;
        table-layout: fixed;
    }

    .tc-table__cell {
        border: 1px solid #dbdbe2;
        padding: 0;
        vertical-align: top;
    }

    .tc-table__area {
        padding: 10px;
        height: 100%;
    }

    .cdx-text-variant--call-out .ce-block__content {
        border: 1px solid rgb(255, 135, 135);
        padding: 8px 16px;
        border-radius: 6px;
        box-shadow: 0 0 0 5px rgba(255, 120, 84, 0.16);
        margin-top: 0.6em;
        margin-bottom: 0.6em;
    }

    .cdx-text-variant--citation .ce-block__content {
        font-style: italic !important;
    }

    .cdx-text-variant--details .ce-block__content {
        font-size: 0.84em;
    }

    .ce-block__content {
        position: relative;
        max-width: 100%;
        margin: 0 auto;
        -webkit-transition: background-color .15s ease;
        transition: background-color .15s ease;
    }

    .cdx-block {
        max-width: 100%;
    }

    .cdx-nested-list {
        margin: 0;
        padding: 0;
        outline: none;
        counter-reset: item;
        list-style: none;
    }

    .cdx-nested-list__item {
        line-height: 1.6em;
        display: flex;
        margin: 2px 0;
    }

    .cdx-nested-list__item-body {
        flex-grow: 2;
    }

    .cdx-nested-list__item-content,
    .cdx-nested-list__item-children {
        flex-basis: 100%;
    }

    .cdx-nested-list--unordered > .cdx-nested-list__item::before {
        content: "•";
    }

    .cdx-nested-list--ordered > .cdx-nested-list__item::before {
        content: counters(item, ".") ". ";
    }

    .cdx-nested-list__item::before {
        counter-increment: item;
        margin-right: 5px;
        white-space: nowrap;
    }

    /* blockquote */
    blockquote {
        width: 100%;
        margin: 50px auto;
        padding: 30px 30px 15px 7%;
        border-left: 8px solid #EA547E;
        position: relative;
        background: #F7F8F9;
    }

    blockquote::before {
        content: "\201C";
        color: #EA547E;
        font-style: italic;
        font-size: 4em;
        position: absolute;
        left: 3%;
        top: 20px;
    }

    blockquote::after {
        content: '';
    }

    blockquote .caption {
        text-align: right;
        display: block;
        color: #333333;
        margin-top: 1em;
        text-decoration: underline;
    }

    /* Link Tools  */
    .link-tool {
        position: relative;
    }

    .ce-block a {
        cursor: pointer;
        text-decoration: underline;
    }


    .link-tool__content--rendered {
        background: #fff;
        border: 1px solid rgba(201, 201, 204, 0.48);
        box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
        border-radius: 6px;
        will-change: filter;
        animation: link-in 450ms 1 cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    .link-tool__content {
        display: block;
        padding: 25px;
        border-radius: 10px;
        box-shadow: 0 0 0 2px #fff;
        color: initial !important;
        text-decoration: none !important;
    }

    .link-tool__image {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0 0 0 30px;
        width: 65px;
        height: 65px;
        border-radius: 3px;
        float: right;
    }

    .link-tool__title {
        font-size: 17px;
        font-weight: 600;
        line-height: 1.5em;
        margin: 0 0 10px 0;
    }

    .link-tool__description {
        margin: 0 0 20px 0;
        font-size: 15px;
        line-height: 1.55em;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .link-tool__anchor {
        display: block;
        font-size: 15px;
        line-height: 1em;
        color: #888 !important;
        border: 0 !important;
        padding: 0 !important;
    }

    .link-tool__content::after {
        content: "";
        clear: both;
        display: table;
    }

    .cdx-attaches--with-file {
        display: flex;
        align-items: center;
        padding: 10px 12px;
        border: 1px solid #EFF0F1;
        border-radius: 7px;
        background: #fff;
    }

    .cdx-attaches--with-file .cdx-attaches__file-icon {
        position: relative;
    }

    .cdx-attaches--with-file .cdx-attaches__file-icon-background {
        background-color: #333;
        width: 27px;
        height: 30px;
        margin-right: 12px;
        color: #fff;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .cdx-attaches--with-file .cdx-attaches__file-info {
        display: grid;
        grid-gap: 4px;
        max-width: calc(100% - 80px);
        margin: auto 0;
        flex-grow: 2;
    }

    .cdx-attaches--with-file .cdx-attaches__title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        outline: none;
        max-width: 90%;
        font-size: 14px;
        font-weight: 500;
        line-height: 1em;
    }

    .cdx-attaches--with-file .cdx-attaches__download-button {
        display: flex;
        align-items: center;
        background: #F8F8F8;
        padding: 6px;
        border-radius: 6px;
        margin: auto 0 auto auto;
    }

    .code {
        min-height: auto;
        font-family: Menlo, Monaco, Consolas, Courier New, monospace;
        color: #41314e;
        line-height: 1.6em;
        font-size: 12px;
        padding: 10px;
        background: #f8f7fa;
        border: 1px solid #f1f1f4;
        box-shadow: none;
        white-space: pre;
        word-wrap: normal;
        overflow-x: auto;
        resize: vertical;
    }

    .cdx-warning {
        background-color: #FFEBF1;
        border: 1px solid #EA547E;
        padding: 15px;
    }

    .cdx-alert {
        position: relative;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 10px;
    }

    .ce-block__content .cdx-alert-primary {
        background-color: #ebf8ff;
        border: 1px solid #4299e1;
        color: #2b6cb0;
    }

    .ce-block__content .cdx-alert-secondary {
        background-color: #f7fafc;
        border: 1px solid #cbd5e0;
        color: #222731;
    }

    .ce-block__content .cdx-alert-info {
        background-color: #e6fdff;
        border: 1px solid #4cd4ce;
        color: #00727c;
    }

    .ce-block__content .cdx-alert-success {
        background-color: #f0fff4;
        border: 1px solid #68d391;
        color: #2f855a;
    }

    .ce-block__content .cdx-alert-warning {
        background-color: #fffaf0;
        border: 1px solid #ed8936;
        color: #c05621;
    }

    .ce-block__content .cdx-alert-danger {
        background-color: #fff5f5;
        border: 1px solid #fc8181;
        color: #c53030;
    }

    .ce-block__content .cdx-alert-light {
        background-color: #fff;
        border: 1px solid #edf2f7;
        color: #1a202c;
    }

    .ce-block__content .cdx-alert-dark {
        background-color: #2d3748;
        border: 1px solid #1a202c;
        color: #d3d3d3;
    }

    .ce-delimiter:before {
        display: inline-block;
        content: "***";
        font-size: 30px;
        line-height: 65px;
        height: 30px;
        letter-spacing: 0.2em;
    }

    .alternate > div {
        width: 800px;
        margin: auto;
    }

    .alternate > div > a {
        display: flex;
    }

    .alternate > div > a > div > .alternate-data {
        padding: 20px 30px !important;
    }

    .alternate > div > a > .f-image > figure {
        width: 349px;
        height: 280px;
        overflow: hidden;
    }

    .alternate > div > a > .f-image > figure > img {
        object-fit: cover;
    }

    .alternate > div > a > .f-data {
        flex: 1;
    }

    @media only screen
    and (max-width: 1024px) {
        .alternate > div {
            width: 100%;
            margin: auto;
        }
    }
    @media only screen
    and (max-width: 768px) {
        .alternate {
            display: flex;
            flex-wrap: wrap;
        }

        .alternate > div {
            width: 46%;
            margin: auto;
        }

        .alternate > div > a > div > .alternate-data {
            padding: 10px !important;
        }

        .alternate > div > a {
            display: flex;
            flex-direction: column;
        }

        .alternate > div > a > .f-image > figure {
            width: 100%;
            height: 200px;
            overflow: hidden;
        }
    }
    @media only screen
    and (max-width: 550px) {
        .alternate > div {
            width: 100%;
            margin: auto;
        }

        alternate > div > a > .f-image > figure {
            width: 100%;
            height: 350px;
            overflow: hidden;
        }
    }
}
